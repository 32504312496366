import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../ui/Header";
import { Menu } from "../ui/Menu";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import Feedback from "react-bootstrap/esm/Feedback";
import checkMatricula from "../../functions/checkMatricula";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_GET_ESTADO_CUENTA = "cliente/estadoCuenta";

export const NuevoVehiculo = () => {
  const { t } = useTranslation();
  const { nombre, apellido, token } = useSelector((state) => state.auth);
  const [validated, setValidated] = useState(false);
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const checkButtonDisabled = () => {
    const matricula = document.getElementById("matricula");
    const tipo_vehiculo = document.getElementById("tipo-vehiculo");
    const tipo = document.getElementById("tipo");
    // const factura = document.getElementById("factura");

    let btn = document.getElementById("btn-add-vehiculo");
    btn.disabled = true;

    if (
      matricula.classList.contains("is-valid") &&
      tipo_vehiculo.value !== "" &&
      tipo.value !== ""
      //&& factura.value !== ""
    ) {
      btn.disabled = false;
    }

    //Comprobamos si el tipo de vehículo es una moto y si es así, cambiamos el tipo de combustible a gasolina
    if (tipo_vehiculo.value === "1") {
      tipo.value = "Gasolina";
    }
  };

  const validateMatricula = async () => {
    const matricula = document.getElementById("matricula");
    const tipo_vehiculo = document.getElementById("tipo-vehiculo");
    const tipo = document.getElementById("tipo");
    // const factura = document.getElementById("factura");

    let btn = document.getElementById("btn-add-vehiculo");
    btn.disabled = true;

    if (matricula.value === "") {
      matricula.setCustomValidity(" ");
      document.getElementById("matricula-feedback").innerHTML = t(
        "datos.vehiculos.nuevo.form.matricula.invalid"
      );
      matricula.classList.add("is-invalid");
    } else {
      await checkMatricula(matricula.value, token).then((matriculaValida) => {
        if (matriculaValida?.code !== 200) {
          matricula.setCustomValidity(" ");
          document.getElementById("matricula-feedback").innerHTML =
            matriculaValida?.data;
          matricula.classList.add("is-invalid");
        } else {
          document.getElementById("matricula-feedback").innerHTML = "";
          matricula.setCustomValidity("");
          matricula.classList.add("is-valid");
          if (
            matricula.classList.contains("is-valid") &&
            tipo_vehiculo.value !== "" &&
            tipo.value !== ""
            // && factura.value !== ""
          ) {
            btn.disabled = false;
          }
        }
      });
    }
  };

  const validateForm = async () => {
    setValidated(false);
    // Recogemos los datos del formulario
    const matricula = document.getElementById("matricula");
    const tipo_vehiculo = document.getElementById("tipo-vehiculo");
    const tipo = document.getElementById("tipo");
    // const factura = document.getElementById("factura");

    // Cambiamos el texto del botón y mostramos el componente miniLoader
    let btn = document.getElementById("btn-add-vehiculo");
    btn.disabled = true;

    let vehicleData = {
      matricula: false,
      tipo_vehiculo: false,
      tipo: false,
      // factura: false,
    };

    if (matricula.value === "") {
      matricula.setCustomValidity(" ");
      document.getElementById("matricula-feedback").innerHTML = t(
        "datos.vehiculos.nuevo.form.matricula.invalid"
      );
      vehicleData.matricula = false;
    } else {
      await checkMatricula(matricula.value, token).then((matriculaValida) => {
        if (matriculaValida?.code !== 200) {
          matricula.setCustomValidity(" ");
          document.getElementById("matricula-feedback").innerHTML =
            matriculaValida?.data;
          vehicleData.matricula = false;
        } else {
          document.getElementById("matricula-feedback").innerHTML = "";
          matricula.setCustomValidity("");
          vehicleData.matricula = true;
        }
      });
    }

    if (tipo_vehiculo.value === "") {
      tipo_vehiculo.setCustomValidity(" ");
      vehicleData.tipo_vehiculo = false;
    } else {
      tipo_vehiculo.setCustomValidity("");
      vehicleData.tipo_vehiculo = true;
    }

    if (tipo.value === "") {
      tipo.setCustomValidity(" ");
      vehicleData.tipo = false;
    } else {
      tipo.setCustomValidity("");
      vehicleData.tipo = true;
    }

    // if (factura.value === "") {
    //   factura.setCustomValidity(" ");
    //   vehicleData.factura = false;
    // } else {
    //   factura.setCustomValidity("");
    //   vehicleData.factura = true;
    // }

    setValidated(true);

    if (
      !vehicleData.matricula ||
      !vehicleData.tipo_vehiculo ||
      !vehicleData.tipo
      // || !vehicleData.factura
    ) {
      btn.disabled = false;
      return false;
    } else {
      return true;
    }
  };

  const addVehiculo = async (e) => {
    e.preventDefault();

    // Recogemos los datos del formulario
    const matricula = document.getElementById("matricula");
    const tipo_vehiculo = document.getElementById("tipo-vehiculo");
    const tipo = document.getElementById("tipo");
    // const factura = document.getElementById("factura");

    let btn = document.getElementById("btn-add-vehiculo");

    if (!validateForm()) {
      return false;
    }

    // Creamos el objeto con los datos del vehículo
    const data = {
      matricula: matricula.value,
      tipo_vehiculo: tipo_vehiculo.value,
      tipo: tipo.value,
      // factura: factura.value,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(`vehiculos`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 201) {
          toast(t("datos.vehiculos.nuevo.form.result.ok"), {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
          if (ValidateToken()) {
            navigate("/datos");
          } else {
            dispatch(startLogout("expired"));
          }
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      btn.disabled = false;
      console.error(error);
    }
  };

  const fetchEstadoCuenta = useCallback(async () => {
    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.get(URL_GET_ESTADO_CUENTA, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          if (response.data.data.bloqueada === 1) {
            if (ValidateToken()) {
              navigate("/datos");
            } else {
              dispatch(startLogout("expired"));
            }
            toast(t("datos.alert.bloqueado.limite.text"));
          }
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchEstadoCuenta();
  }, [fetchEstadoCuenta]);

  return (
    <>
      <Menu />
      <Container className="content-page">
        <Header name={nombre + " " + apellido} />
        <Container>
          <Row className="page-header pb-4">
            <h1>
              <Link to={`/datos`} className="no-decoration">
                &lt;
              </Link>
              {t("datos.vehiculos.nuevo.h1")}
            </h1>
          </Row>
        </Container>
        <Container>
          <Row className="content-section mb-4">
            <Form
              className="datos-nuevo-vehiculo"
              autoComplete="off"
              noValidate
              validated={validated}
              onSubmit={(e) => {
                addVehiculo(e);
              }}
            >
              <Row>
                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.vehiculos.nuevo.form.matricula")}
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      maxLength="12"
                      id="matricula"
                      placeholder={t("datos.vehiculos.nuevo.form.matricula")}
                      onBlur={validateMatricula}
                    />
                    <Feedback type="invalid" id="matricula-feedback">
                      {t("datos.vehiculos.nuevo.form.matricula.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.vehiculos.nuevo.form.tipo-vehiculo")}
                    className="mb-3"
                  >
                    <Form.Select
                      aria-label={t("datos.vehiculos.nuevo.form.tipo-vehiculo")}
                      id="tipo-vehiculo"
                      placeholder={t(
                        "datos.vehiculos.nuevo.form.tipo-vehiculo"
                      )}
                      defaultValue=""
                      onChange={checkButtonDisabled}
                    >
                      <option value="" disabled>
                        {t("datos.vehiculos.nuevo.form.tipo-vehiculo.select")}
                      </option>
                      <option value="0">
                        {t("datos.vehiculos.nuevo.form.tipo-vehiculo.turismo")}
                      </option>
                      <option value="1">
                        {t("datos.vehiculos.nuevo.form.tipo-vehiculo.moto")}
                      </option>
                      <option value="2">
                        {t("datos.vehiculos.nuevo.form.tipo-vehiculo.camion")}
                      </option>
                    </Form.Select>
                    <Feedback type="invalid">
                      {t("datos.vehiculos.nuevo.form.tipo-vehiculo.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.vehiculos.nuevo.form.combustible")}
                    className="mb-3"
                  >
                    <Form.Select
                      aria-label={t("datos.vehiculos.nuevo.form.combustible")}
                      id="tipo"
                      placeholder={t("datos.vehiculos.nuevo.form.combustible")}
                      defaultValue=""
                      onChange={checkButtonDisabled}
                    >
                      <option value="" disabled>
                        {t("datos.vehiculos.nuevo.form.combustible.select")}
                      </option>
                      <option value="Petróleo">
                        {t("datos.vehiculos.nuevo.form.combustible.diesel")}
                      </option>
                      <option value="Gasolina">
                        {t("datos.vehiculos.nuevo.form.combustible.gasolina")}
                      </option>
                    </Form.Select>
                    <Feedback type="invalid">
                      {t("datos.vehiculos.nuevo.form.combustible.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col>

                {/* <Col sm={12} lg={6}>
                  <FloatingLabel
                    label={t("datos.vehiculos.nuevo.form.factura")}
                    className="mb-3"
                  >
                    <Form.Select
                      aria-label={t("datos.vehiculos.nuevo.form.factura")}
                      id="factura"
                      placeholder={t("datos.vehiculos.nuevo.form.factura")}
                      defaultValue="1"
                      onChange={checkButtonDisabled}
                    >
                      <option value="1">
                        {t("datos.vehiculos.nuevo.form.factura.si")}
                      </option>
                      <option value="0">
                        {t("datos.vehiculos.nuevo.form.factura.no")}
                      </option>
                    </Form.Select>
                    <Feedback type="invalid">
                      {t("datos.vehiculos.nuevo.form.factura.invalid")}
                    </Feedback>
                  </FloatingLabel>
                </Col> */}
              </Row>
              <Row>
                <div className="d-grid gap-2 mt-5">
                  <Button
                    id="btn-add-vehiculo"
                    variant="primary"
                    size="lg"
                    onClick={(e) => {
                      addVehiculo(e);
                    }}
                  >
                    {t("datos.vehiculos.nuevo.form.guardar-btn")}
                  </Button>
                </div>
              </Row>
            </Form>
          </Row>
        </Container>
      </Container>
    </>
  );
};

import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, FloatingLabel } from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import checkMatricula from "../../../functions/checkMatricula";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

// creating functional component ans getting props from app.js and destucturing them
export const StepOne = ({ nextStep, handleFormData, values, flota }) => {
  const [validated, setValidated] = useState(false);

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);

  const validateForm = async (onlyPlate = false) => {
    setValidated(false);

    const flota = document.getElementById("flotas-registro-vehiculo-flota");

    const nombreFlota = flota.value;
    const flotaID = flota.dataset.id;

    const matricula = document.getElementById(
      "flotas-registro-vehiculo-matricula"
    );

    const descripcion = document.getElementById(
      "flotas-registro-vehiculo-descripcion"
    );

    const factura = document.getElementById("flotas-registro-vehiculo-factura");

    const tipo_vehiculo = document.getElementById(
      "flotas-registro-vehiculo-tipo-vehiculo"
    );
    const combustible = document.getElementById(
      "flotas-registro-vehiculo-combustible"
    );
    const limite_credito = document.getElementById(
      "flotas-registro-vehiculo-limite-credito"
    );
    const cantidad_limite = document.getElementById(
      "flotas-registro-vehiculo-limite-mensual"
    );
    const pin = document.getElementById("flotas-registro-vehiculo-pin");
    const pin_txt = document.getElementById(
      "flotas-registro-vehiculo-pin-input"
    );
    const pin_txt_repeat = document.getElementById(
      "flotas-registro-vehiculo-pin-repeat"
    );

    let dataValid = {
      matricula: false,
      factura: false,
      tipoVehiculo: false,
      combustible: false,
      limiteCredito: false,
      cantidadLimite: false,
      pinTxt: false,
      pinTxtRepeat: false,
    };

    if (onlyPlate) {
      if (matricula.value === "") {
        matricula.setCustomValidity(" ");
        document.getElementById(
          "flotas-registro-vehiculo-step-1-form-matricula-invalid"
        ).innerHTML = t(
          "flotas.registro-vehiculo.step-1.form.matricula.invalid"
        );
        document.getElementById(
          "flotas-registro-vehiculo-step-1-form-matricula-invalid"
        ).style = "display: block;";
        dataValid.matricula = false;
        matricula.classList.remove("is-valid");
        matricula.classList.add("is-invalid");
      } else {
        if (dataValid.matricula === false) {
          await checkMatricula(matricula.value, token).then(
            (matriculaValida) => {
              if (matriculaValida?.code !== 200) {
                matricula.setCustomValidity(" ");
                document.getElementById(
                  "flotas-registro-vehiculo-step-1-form-matricula-invalid"
                ).innerHTML = matriculaValida?.data;
                document.getElementById(
                  "flotas-registro-vehiculo-step-1-form-matricula-invalid"
                ).style = "display: block;";
                dataValid.matricula = false;
                matricula.classList.remove("is-valid");
                matricula.classList.add("is-invalid");
              } else {
                document.getElementById(
                  "flotas-registro-vehiculo-step-1-form-matricula-invalid"
                ).innerHTML = "";
                document.getElementById(
                  "flotas-registro-vehiculo-step-1-form-matricula-invalid"
                ).style = "display: none;";
                matricula.setCustomValidity("");
                matricula.classList.remove("is-invalid");
                matricula.classList.add("is-valid");
                dataValid.matricula = true;
              }
            }
          );
        }
      }
    } else {
      dataValid.matricula = true;
      if (factura.value === "") {
        factura.setCustomValidity(" ");
        dataValid.factura = false;
      } else {
        factura.setCustomValidity("");
        dataValid.factura = true;
      }

      if (tipo_vehiculo.value === "") {
        tipo_vehiculo.setCustomValidity(" ");
        dataValid.tipoVehiculo = false;
      } else {
        tipo_vehiculo.setCustomValidity("");
        dataValid.tipoVehiculo = true;
      }
      if (tipo_vehiculo.value === "") {
        tipo_vehiculo.setCustomValidity(" ");
        dataValid.tipoVehiculo = false;
      } else {
        tipo_vehiculo.setCustomValidity("");
        dataValid.tipoVehiculo = true;
      }

      if (combustible.value === "") {
        combustible.setCustomValidity(" ");
        dataValid.combustible = false;
      } else {
        combustible.setCustomValidity("");
        dataValid.combustible = true;
      }
      if (combustible.value === "") {
        combustible.setCustomValidity(" ");
        dataValid.combustible = false;
      } else {
        combustible.setCustomValidity("");
        dataValid.combustible = true;
      }

      if (limite_credito.value === "") {
        limite_credito.setCustomValidity(" ");
        dataValid.limiteCredito = false;
      } else if (limite_credito.value === "1") {
        if (cantidad_limite.value === "") {
          cantidad_limite.setCustomValidity(" ");
          dataValid.cantidadLimite = false;
        } else {
          cantidad_limite.setCustomValidity("");
          dataValid.cantidadLimite = true;
        }
        limite_credito.setCustomValidity("");
        dataValid.limiteCredito = true;
      } else {
        limite_credito.setCustomValidity("");
        dataValid.limiteCredito = true;
        dataValid.cantidadLimite = true;
      }
      if (limite_credito.value === "") {
        limite_credito.setCustomValidity(" ");
        dataValid.limiteCredito = false;
      } else if (limite_credito.value === "1") {
        if (cantidad_limite.value === "") {
          cantidad_limite.setCustomValidity(" ");
          dataValid.cantidadLimite = false;
        } else {
          cantidad_limite.setCustomValidity("");
          dataValid.cantidadLimite = true;
        }
        limite_credito.setCustomValidity("");
        dataValid.limiteCredito = true;
      } else {
        limite_credito.setCustomValidity("");
        dataValid.limiteCredito = true;
        dataValid.cantidadLimite = true;
      }

      if (
        pin_txt.value === "" ||
        pin_txt.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity(" ");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pinTxt = false;
        dataValid.pinTxtRepeat = false;
      } else if (
        pin_txt_repeat.value === "" ||
        pin_txt_repeat.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity("");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pinTxtRepeat = false;
        dataValid.pinTxt = true;
      } else if (pin_txt.value !== pin_txt_repeat.value) {
        pin_txt_repeat.setCustomValidity(" ");
        pin_txt.setCustomValidity(" ");
        dataValid.pinTxtRepeat = false;
        dataValid.pinTxt = false;
      } else {
        pin_txt_repeat.setCustomValidity("");
        pin_txt.setCustomValidity("");
        dataValid.pinTxtRepeat = true;
        dataValid.pinTxt = true;
      }
      if (
        pin_txt.value === "" ||
        pin_txt.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity(" ");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pinTxt = false;
        dataValid.pinTxtRepeat = false;
      } else if (
        pin_txt_repeat.value === "" ||
        pin_txt_repeat.value.length !== 4 ||
        isNaN(pin_txt.value)
      ) {
        pin_txt.setCustomValidity("");
        pin_txt_repeat.setCustomValidity(" ");
        dataValid.pinTxtRepeat = false;
        dataValid.pinTxt = true;
      } else if (pin_txt.value !== pin_txt_repeat.value) {
        pin_txt_repeat.setCustomValidity(" ");
        pin_txt.setCustomValidity(" ");
        dataValid.pinTxtRepeat = false;
        dataValid.pinTxt = false;
      } else {
        pin_txt_repeat.setCustomValidity("");
        pin_txt.setCustomValidity("");
        dataValid.pinTxtRepeat = true;
        dataValid.pinTxt = true;
      }

      setValidated(true);
    }

    if (
      !dataValid.matricula ||
      !dataValid.factura ||
      !dataValid.tipoVehiculo ||
      !dataValid.combustible ||
      !dataValid.limiteCredito ||
      !dataValid.cantidadLimite ||
      !dataValid.pinTxt ||
      !dataValid.pinTxtRepeat
    ) {
      return false;
    } else {
      return true;
    }
  };

  // after form submit validating the form data using validator
  const submitFormData = async (e) => {
    e.preventDefault();
    const flota = document.getElementById("flotas-registro-vehiculo-flota");

    const nombreFlota = flota.value;
    const flotaID = flota.dataset.id;

    const matricula = document.getElementById(
      "flotas-registro-vehiculo-matricula"
    );

    const descripcion = document.getElementById(
      "flotas-registro-vehiculo-descripcion"
    );

    const factura = document.getElementById("flotas-registro-vehiculo-factura");

    const tipo_vehiculo = document.getElementById(
      "flotas-registro-vehiculo-tipo-vehiculo"
    );
    const combustible = document.getElementById(
      "flotas-registro-vehiculo-combustible"
    );
    const limite_credito = document.getElementById(
      "flotas-registro-vehiculo-limite-credito"
    );
    const cantidad_limite = document.getElementById(
      "flotas-registro-vehiculo-limite-mensual"
    );
    const pin = document.getElementById("flotas-registro-vehiculo-pin");
    const pin_txt = document.getElementById(
      "flotas-registro-vehiculo-pin-input"
    );
    const pin_txt_repeat = document.getElementById(
      "flotas-registro-vehiculo-pin-repeat"
    );

    let validated = await validateForm();

    if (!validated) {
      return;
    }

    values.flotaID = flotaID;
    values.flotaNombre = nombreFlota;
    values.matricula = matricula.value;
    values.factura = factura.value;
    values.descripcion = descripcion.value;
    values.tipoVehiculo = tipo_vehiculo.value;
    values.combustible = combustible.value;
    values.limiteCredito = limite_credito.value;
    values.cantidadLimite = cantidad_limite?.value || "";
    values.pinTxt = pin_txt?.value || "";
    values.pinTxtRepeat = pin_txt_repeat?.value || "";

    nextStep();
  };

  const handleReadOnly = (id, readOnly) => {
    document.getElementById(id).readOnly = readOnly;
  };

  const checkTipoVehiculo = () => {
    //Comprobamos si el tipo de vehiculo es una moto, si es así, el combustible solo puede ser gasolina
    const tipo_vehiculo = document.getElementById(
      "flotas-registro-vehiculo-tipo-vehiculo"
    );

    const combustible = document.getElementById(
      "flotas-registro-vehiculo-combustible"
    );

    if (tipo_vehiculo.value === "1") {
      combustible.value = "Gasolina";
      handleReadOnly("flotas-registro-vehiculo-combustible", true);
    } else {
      handleReadOnly("flotas-registro-vehiculo-combustible", false);
    }
  };

  return (
    <>
      <Form
        className="datos-nuevo-vehiculo"
        autoComplete="off"
        onSubmit={(e) => submitFormData(e)}
        noValidate
        validated={validated}
        name="datos-nuevo-vehiculo"
      >
        <Row>
          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.flota")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                aria-label={t("flotas.registro-vehiculo.step-1.form.flota")}
                placeholder={t("flotas.registro-vehiculo.step-1.form.flota")}
                defaultValue={
                  values.flotaID !== ""
                    ? values.flotaNombre
                    : flota.nombre_cuenta
                }
                id="flotas-registro-vehiculo-flota"
                data-id={
                  values.flotaID !== "" ? values.flotaID : flota.id_cuenta
                }
                readOnly={true}
              ></Form.Control>
            </FloatingLabel>
          </Col>
          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.matricula")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                maxLength="12"
                id="flotas-registro-vehiculo-matricula"
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.matricula"
                )}
                defaultValue={values.matricula}
                readOnly={values.matricula !== "" ? true : false}
                onBlur={() => values.matricula === "" && validateForm(true)}
              />
              <Feedback
                type="invalid"
                id="flotas-registro-vehiculo-step-1-form-matricula-invalid"
              >
                {t("flotas.registro-vehiculo.step-1.form.matricula.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.factura")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t("flotas.registro-vehiculo.step-1.form.factura")}
                placeholder={t("flotas.registro-vehiculo.step-1.form.factura")}
                id="flotas-registro-vehiculo-factura"
                defaultValue={values.factura}
              >
                <option value="1">
                  {t("flotas.registro-vehiculo.step-1.form.factura.si")}
                </option>
                <option value="0">
                  {t("flotas.registro-vehiculo.step-1.form.factura.no")}
                </option>
              </Form.Select>
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-1.form.factura.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.descripcion")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                maxLength="12"
                id="flotas-registro-vehiculo-descripcion"
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.descripcion"
                )}
                defaultValue={values.descripcion}
              />
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-1.form.descripcion.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.tipo-vehiculo")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t(
                  "flotas.registro-vehiculo.step-1.form.tipo-vehiculo"
                )}
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.tipo-vehiculo"
                )}
                id="flotas-registro-vehiculo-tipo-vehiculo"
                defaultValue={values.tipoVehiculo}
                onChange={checkTipoVehiculo}
              >
                <option value="" disabled>
                  {t(
                    "flotas.registro-vehiculo.step-1.form.tipo-vehiculo.select"
                  )}
                </option>
                <option value="0">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.tipo-vehiculo.turismo"
                  )}
                </option>
                <option value="1">
                  {t("flotas.registro-vehiculo.step-1.form.tipo-vehiculo.moto")}
                </option>
                <option value="2">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.tipo-vehiculo.camion"
                  )}
                </option>
              </Form.Select>
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-1.form.tipo-vehiculo.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.combustible")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t(
                  "flotas.registro-vehiculo.step-1.form.combustible"
                )}
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.combustible"
                )}
                id="flotas-registro-vehiculo-combustible"
                defaultValue={values.combustible}
                onChange={checkTipoVehiculo}
              >
                <option value="" disabled>
                  {t("flotas.registro-vehiculo.step-1.form.combustible.select")}
                </option>
                <option value="Petróleo">
                  {t("flotas.registro-vehiculo.step-1.form.combustible.diesel")}
                </option>
                <option value="Gasolina">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.combustible.gasolina"
                  )}
                </option>
              </Form.Select>
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-1.form.combustible.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.pin-input")}
              className="mb-3"
            >
              <Form.Control
                type="password"
                id="flotas-registro-vehiculo-pin-input"
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.pin-input"
                )}
                defaultValue={values.pinTxt}
                maxLength="4"
                pattern="[0-9]{4}"
                required
                autoComplete="new-password"
              />
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-1.form.pin-input.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.pin-repeat")}
              className="mb-3"
            >
              <Form.Control
                type="password"
                id="flotas-registro-vehiculo-pin-repeat"
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.pin-repeat"
                )}
                defaultValue={values.pinTxtRepeat}
                maxLength="4"
                pattern="[0-9]{4}"
                required
                autoComplete="new-password"
              />
              <Feedback type="invalid">
                {t("flotas.registro-vehiculo.step-1.form.pin-repeat.invalid")}
              </Feedback>
            </FloatingLabel>
          </Col>

          <Col sm={12} lg={6}>
            <FloatingLabel
              label={t("flotas.registro-vehiculo.step-1.form.limite-credito")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t(
                  "flotas.registro-vehiculo.step-1.form.limite-credito"
                )}
                placeholder={t(
                  "flotas.registro-vehiculo.step-1.form.limite-credito"
                )}
                id="flotas-registro-vehiculo-limite-credito"
                defaultValue={values.limiteCredito}
                onChange={handleFormData("limiteCredito")}
              >
                <option value="" disabled>
                  {t(
                    "flotas.registro-vehiculo.step-1.form.limite-credito.select"
                  )}
                </option>
                <option value="-1">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.limite-credito.sin-limite"
                  )}
                </option>
                <option value="1">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.limite-credito.definir"
                  )}
                </option>
              </Form.Select>
              <Feedback type="invalid">
                {t(
                  "flotas.registro-vehiculo.step-1.form.limite-credito.invalid"
                )}
              </Feedback>
            </FloatingLabel>
          </Col>

          {values.limiteCredito == 1 && (
            <Col sm={12} lg={6}>
              <FloatingLabel
                label={t("flotas.registro-vehiculo.step-1.form.limite-mensual")}
                className="mb-3"
              >
                <Form.Control
                  type="number"
                  placeholder={t(
                    "flotas.registro-vehiculo.step-1.form.limite-mensual"
                  )}
                  defaultValue={values.cantidadLimite}
                  id="flotas-registro-vehiculo-limite-mensual"
                />
                <Feedback type="invalid">
                  {t(
                    "flotas.registro-vehiculo.step-1.form.limite-mensual.invalid"
                  )}
                </Feedback>
              </FloatingLabel>
            </Col>
          )}

          <Row>
            <div className="d-grid gap-2 mt-5">
              <Button
                variant="primary"
                size="lg"
                type="submit"
                onClick={(e) => {
                  submitFormData(e);
                }}
              >
                {t("flotas.registro-vehiculo.step-1.form.btn-next")}
              </Button>
            </div>
          </Row>
        </Row>
      </Form>
    </>
  );
};

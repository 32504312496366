import { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
} from "react-bootstrap";
import Feedback from "react-bootstrap/esm/Feedback";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import axiosApi from "../../axios/axios-api";
import checkMatricula from "../../functions/checkMatricula";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

const URL_PUT_REACTIVAR_VEHICULO_FLOTAS = "flotas/reactivarvehiculo/";

export function ModalNuevoVehiculo(props) {
  const { vehiculo } = props;
  const { t } = useTranslation();
  const { token, flotaActiva } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [validated, setValidated] = useState(props.validated);
  const dispatch = useDispatch();

  const normalizarMatricula = (mat) => {
    if (!mat) return "";

    let matriculaNormalizada = mat?.split("-");
    matriculaNormalizada =
      matriculaNormalizada[matriculaNormalizada.length - 1];

    return matriculaNormalizada;
  };

  const validateForm = async () => {
    setValidated(false);
    // Recogemos los datos del formulario
    const matricula = document.getElementById("modal-flotas-nuevo-matricula");
    const tipo_vehiculo = document.getElementById(
      "modal-flotas-nuevo-tipo-vehiculo"
    );
    const tipo = document.getElementById("modal-flotas-nuevo-combustible");
    const factura = document.getElementById("modal-flotas-nuevo-factura");

    // Cambiamos el texto del botón y mostramos el componente miniLoader
    let btn = document.getElementById("modal-flotas-nuevo-guardar-btn");
    btn.disabled = true;

    let vehicleData = {
      matricula: false,
      tipo_vehiculo: false,
      tipo: false,
      factura: false,
    };

    if (matricula.value === "") {
      matricula.setCustomValidity(" ");
      document.getElementById(
        "modal-flotas-nuevo-matricula-feedback"
      ).innerHTML = t("datos.vehiculos.nuevo.form.matricula.invalid");
      document.getElementById("modal-flotas-nuevo-matricula-feedback").style =
        "display: block;";
      vehicleData.matricula = false;
    } else {
      await checkMatricula(matricula.value, token).then((matriculaValida) => {
        if (matriculaValida?.code !== 200) {
          matricula.setCustomValidity(" ");
          document.getElementById(
            "modal-flotas-nuevo-matricula-feedback"
          ).innerHTML = matriculaValida?.data;
          document.getElementById(
            "modal-flotas-nuevo-matricula-feedback"
          ).style = "display: block;";
          vehicleData.matricula = false;
        } else {
          document.getElementById(
            "modal-flotas-nuevo-matricula-feedback"
          ).innerHTML = "";
          document.getElementById(
            "modal-flotas-nuevo-matricula-feedback"
          ).style = "display: none;";
          matricula.setCustomValidity("");
          vehicleData.matricula = true;
        }
      });
    }

    if (tipo_vehiculo.value === "") {
      tipo_vehiculo.setCustomValidity(" ");
      vehicleData.tipo_vehiculo = false;
    } else {
      tipo_vehiculo.setCustomValidity("");
      vehicleData.tipo_vehiculo = true;
    }

    if (tipo.value === "") {
      tipo.setCustomValidity(" ");
      vehicleData.tipo = false;
    } else {
      tipo.setCustomValidity("");
      vehicleData.tipo = true;
    }

    if (factura.value === "") {
      factura.setCustomValidity(" ");
      vehicleData.factura = false;
    } else {
      factura.setCustomValidity("");
      vehicleData.factura = true;
    }

    setValidated(true);

    if (
      !vehicleData.matricula ||
      !vehicleData.tipo_vehiculo ||
      !vehicleData.tipo ||
      !vehicleData.factura
    ) {
      btn.disabled = false;
      return false;
    } else {
      return true;
    }
  };

  const addVehiculo = async (e) => {
    e.preventDefault();

    // Recogemos los datos del formulario
    const matricula = document.getElementById("modal-flotas-nuevo-matricula");
    const tipo_vehiculo = document.getElementById(
      "modal-flotas-nuevo-tipo-vehiculo"
    );
    const tipo = document.getElementById("modal-flotas-nuevo-combustible");
    const factura = document.getElementById("modal-flotas-nuevo-factura");

    // Cambiamos el texto del botón y mostramos el componente miniLoader
    let btn = document.getElementById("modal-flotas-nuevo-guardar-btn");

    if (!validateForm()) {
      return false;
    }

    setValidated(true);

    // Creamos el objeto con los datos del vehículo
    const data = {
      matricula: matricula.value,
      tipo_vehiculo: tipo_vehiculo.value,
      tipo: tipo.value,
      factura: factura.value,
      idCcp: flotaActiva.id_cuenta,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.post(`vehiculos`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 201) {
          let data_reactivar = {
            idCcp: flotaActiva.id_cuenta,
          };
          try {
            const responseReactivate = await axiosApi.put(
              `${URL_PUT_REACTIVAR_VEHICULO_FLOTAS}${vehiculo.idTar}`,
              data_reactivar,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: "Bearer " + token,
                },
              }
            );
            if (responseReactivate?.data?.code === 200) {
              props.onHide(true, {
                tipo_vehiculo: tipo_vehiculo.value,
                tipo: tipo.value,
              });
              toast(responseReactivate?.data?.message, {
                icon: <LogoPetroprix />,
              });
              btn.disabled = false;
            } else {
              toast(responseReactivate?.data?.message, {
                icon: <LogoPetroprix />,
              });
              btn.disabled = false;
            }
          } catch (error) {
            console.error(error);
            btn.disabled = false;
          }
        } else if (response?.data?.code === 403) {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          props.onHide();
          btn.disabled = false;
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
          btn.disabled = false;
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      btn.disabled = false;
      console.error(error);
    }
    setValidated(false);
  };

  useEffect(() => {
    if (props.show) {
      setValidated(false);
    }
  }, [props.show]);

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("flotas.modal.nuevo.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant="warning">{t("flotas.modal.nuevo.label")}</Alert>
          <Form
            autoComplete="off"
            noValidate
            validated={validated}
            onSubmit={(e) => addVehiculo(e)}
          >
            <FloatingLabel
              label={t("datos.vehiculos.nuevo.form.matricula")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                maxLength="12"
                id="modal-flotas-nuevo-matricula"
                placeholder={t("datos.vehiculos.nuevo.form.matricula")}
                readOnly
                defaultValue={normalizarMatricula(vehiculo?.matricula)}
                onBlur={validateForm}
              />
              <Feedback
                type="invalid"
                id="modal-flotas-nuevo-matricula-feedback"
              >
                {t("flotas.modal.nuevo.form.matricula.invalid")}
              </Feedback>
            </FloatingLabel>
            <FloatingLabel
              label={t("datos.modal.editar.form.tipo-vehiculo")}
              className="mb-3"
            >
              <Form.Select
                aria-label="{t('datos.modal.editar.form.tipo-vehiculo')}"
                placeholder={t("datos.modal.editar.form.tipo-vehiculo")}
                defaultValue={
                  vehiculo?.tipo_vehiculo !== null
                    ? vehiculo?.tipo_vehiculo
                    : ""
                }
                id="modal-flotas-nuevo-tipo-vehiculo"
              >
                <option value="" disabled>
                  {t("datos.modal.editar.form.tipo-vehiculo.select")}
                </option>
                <option value="0">
                  {t("datos.modal.editar.form.tipo-vehiculo.turismo")}
                </option>
                <option value="1">
                  {t("datos.modal.editar.form.tipo-vehiculo.moto")}
                </option>
                <option value="2">
                  {t("datos.modal.editar.form.tipo-vehiculo.camion")}
                </option>
              </Form.Select>
              <Feedback
                type="invalid"
                id="modal-flotas-nuevo-tipo-vehiculo-feedback"
              >
                {t("flotas.modal.nuevo.form.tipo.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("datos.modal.editar.form.combustible")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t("datos.modal.editar.form.combustible")}
                placeholder={t("datos.modal.editar.form.combustible")}
                defaultValue={
                  vehiculo?.tipo_combustible !== null
                    ? vehiculo?.tipo_combustible
                    : ""
                }
                id="modal-flotas-nuevo-combustible"
              >
                <option value="" disabled>
                  {t("datos.modal.editar.form.combustible.select")}
                </option>
                <option value="Petróleo">
                  {t("datos.modal.editar.form.combustible.diesel")}
                </option>
                <option value="Gasolina">
                  {t("datos.modal.editar.form.combustible.gasolina")}
                </option>
              </Form.Select>
              <Feedback
                type="invalid"
                id="modal-flotas-nuevo-combustible-feedback"
              >
                {t("flotas.modal.nuevo.form.combustible.invalid")}
              </Feedback>
            </FloatingLabel>

            <FloatingLabel
              label={t("datos.modal.editar.form.factura")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t("datos.modal.editar.form.factura")}
                placeholder={t("datos.modal.editar.form.factura")}
                defaultValue=""
                id="modal-flotas-nuevo-factura"
              >
                <option value="" disabled>
                  {t("datos.modal.editar.form.factura.select")}
                </option>
                <option value="0">
                  {t("datos.modal.editar.form.factura.no")}
                </option>
                <option value="1">
                  {t("datos.modal.editar.form.factura.si")}
                </option>
              </Form.Select>
              <Feedback type="invalid" id="modal-flotas-nuevo-factura-feedback">
                {t("flotas.modal.nuevo.form.factura.invalid")}
              </Feedback>
            </FloatingLabel>

            <div className="d-grid gap-2 mt-5">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={() => props.onHide(false)}
              >
                {t("datos.modal.editar.form.cancel-btn")}
              </Button>
              <Button
                variant="primary"
                size="lg"
                id="modal-flotas-nuevo-guardar-btn"
                onClick={(e) => addVehiculo(e)}
              >
                {t("datos.modal.editar.form.guardar-btn")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Container>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { Button, Container, FloatingLabel, Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosApi from "../../axios/axios-api";
import { LogoPetroprix } from "../ui/LogoPetroprix";
import { useDispatch, useSelector } from "react-redux";
import { ValidateToken } from "../../helpers/validateToken";
import { startLogout } from "../../store/auth";

export function ModalEditarVehiculo(props) {
  const { id, matricula, tipo, tipo_vehiculo, factura } = props;
  const { t } = useTranslation();
  const [editTipoVehiculo, setEditTipoVehiculo] = useState();
  // const [editFactura, setEditFactura] = useState();
  const [editTipoCombustible, setEditTipoCombustible] = useState();

  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    setEditTipoVehiculo(tipo_vehiculo);
    // setEditFactura(factura);
    setEditTipoCombustible(tipo);
  }, [tipo_vehiculo, factura]);

  const editVehicle = async () => {
    const data = {
      // factura: editFactura,
      tipo_vehiculo: editTipoVehiculo,
      tipo: editTipoCombustible,
    };

    try {
      if (process.env.REACT_APP_URL_API && ValidateToken()) {
        const response = await axiosApi.put(`vehiculos/${id}`, data, {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        });
        if (response?.data?.code === 200) {
          // cerramos el modal
          props.onHide(true);
          toast(t("datos.modal.editar.result.ok"), {
            icon: <LogoPetroprix />,
          });
        } else {
          toast(response.data.message, {
            icon: <LogoPetroprix />,
          });
        }
      } else {
        dispatch(startLogout("expired"));
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Container className="py-5">
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("datos.modal.editar.title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form autoComplete="off">
            <FloatingLabel
              label={t("datos.modal.editar.form.matricula")}
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder={t("datos.modal.editar.form.matricula")}
                disabled
                value={matricula}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingSelect"
              label={t("datos.modal.editar.form.tipo-vehiculo")}
              className="mb-3"
            >
              <Form.Select
                aria-label="{t('datos.modal.editar.form.tipo-vehiculo')}"
                placeholder={t("datos.modal.editar.form.tipo-vehiculo")}
                defaultValue={tipo_vehiculo}
                onChange={(e) => setEditTipoVehiculo(e.target.value)}
              >
                <option value="" disabled>
                  {t("datos.modal.editar.form.tipo-vehiculo.select")}
                </option>
                <option value="0">
                  {t("datos.modal.editar.form.tipo-vehiculo.turismo")}
                </option>
                <option value="1">
                  {t("datos.modal.editar.form.tipo-vehiculo.moto")}
                </option>
                <option value="2">
                  {t("datos.modal.editar.form.tipo-vehiculo.camion")}
                </option>
              </Form.Select>
            </FloatingLabel>

            <FloatingLabel
              controlId="floatingSelect"
              label={t("datos.modal.editar.form.combustible")}
              className="mb-3"
            >
              <Form.Select
                aria-label={t("datos.modal.editar.form.combustible")}
                placeholder={t("datos.modal.editar.form.combustible")}
                defaultValue={tipo}
                onChange={(e) => setEditTipoCombustible(e.target.value)}
              >
                <option value="" disabled>
                  {t("datos.modal.editar.form.combustible.select")}
                </option>
                <option value="Petróleo">
                  {t("datos.modal.editar.form.combustible.diesel")}
                </option>
                <option value="Gasolina">
                  {t("datos.modal.editar.form.combustible.gasolina")}
                </option>
              </Form.Select>
            </FloatingLabel>

            {/* <FloatingLabel
              controlId="floatingSelect"
              label={t("datos.modal.editar.form.factura")}
              className="mb-3"
              onChange={(e) => setEditFactura(e.target.value)}
            >
              <Form.Select
                aria-label={t("datos.modal.editar.form.factura")}
                placeholder={t("datos.modal.editar.form.factura")}
                defaultValue={factura}
              >
                <option value="1">
                  {t("datos.modal.editar.form.factura.si")}
                </option>
                <option value="0">
                  {t("datos.modal.editar.form.factura.no")}
                </option>
              </Form.Select>
            </FloatingLabel> */}

            <div className="d-grid gap-2 mt-5">
              <Button
                variant="outline-primary"
                size="lg"
                onClick={() => props.onHide(false)}
              >
                {t("datos.modal.editar.form.cancel-btn")}
              </Button>
              <Button variant="primary" size="lg" onClick={editVehicle}>
                {t("datos.modal.editar.form.guardar-btn")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Container>
    </Modal>
  );
}
